import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { API_URL } from "./Config/Config";
import MapBle from "./MapBle/MapBle";
import Table from "./Table";
import SpinnerLoad from "./components/SpinnerLoad";
 
export default function MianPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const axios = require("axios").default;
  const [map, setMap] = useState([]);
  const [pageError, setPageerror] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [isEventStop, setIsEventStop] = useState(false);
  const [nameTitle, setNameTitle] = useState("");
  const [apiCall, setApiCall] = useState(true);


  let navigate = useNavigate();

  useEffect(() => {
    if (apiCall) {
      setApiCall(false)
      getMap();
    }
  }, [apiCall]);

  async function getMap() {
    try {
      const response = await axios.get(`${API_URL}/v1/alarm/get-alarm-loaction-details/${id}`);
      if (response?.status === 200) {

        const alarmLocationDetails = response?.data?.data?.alarmLocationDetails;
        const userAlertData = response?.data?.data?.userAlertData;

        setMap(response?.data?.data);
        setPageerror(true);
        setNameTitle(response?.data?.data?.userAlertData?.userAlert?.fullName);
        if (
          response?.data?.data?.alarmLocationDetails.length <= 1 &&
          response?.data?.data?.emergencyRecordings?.length === 0
        ) {
          //no location and no recording data
          setMsgError(
            <div
              className="alert"
              style={{
                backgroundColor: "#FFF3CD",
                textAlign: "center",
                borderRadius: "5px",
              }}
              role="alert"
            >
              No Data is available for the selected user. Kindly check back later.
            </div>
          );
        } else if (response?.data?.data?.alarmLocationDetails.length <= 1) {
          //only no location
          setMsgError(
            <div
              className="alert"
              style={{
                backgroundColor: "#FFF3CD",
                textAlign: "center",
                borderRadius: "5px",
              }}
              role="alert"
            >
              Currently, No location data is available. Kindly, check back later.
            </div>
          );
        }
        if (
          Date.parse(response?.data?.data?.alarmLocationDetails[0].logTime) <
          Date.parse(response?.data?.data?.userAlertData?.endedAt)
        ) {
          const errorMessage = userAlertData?.isStoppedBySystem
            ? `${userAlertData?.userAlert?.fullName}'s location will no longer be updated since it has been more than 24 hours since the alert was started. Please reach out to ${userAlertData?.userAlert?.fullName} to check if everything is ok.`
            : `${userAlertData?.userAlert?.fullName} has stopped the alert and their location will not be updated anymore.`;

          const alertStartDateMessage = `Alert started at : ${new Date(alarmLocationDetails[0]?.logTime).toLocaleString([], { hour12: true })}`;
          let alertLastDateMessage = `Last updated at: ${new Date(alarmLocationDetails[alarmLocationDetails?.length - 1]?.logTime).toLocaleString([], { hour12: true })}`;
          if (userAlertData?.endedAt && userAlertData?.isStoppedBySystem)
            alertLastDateMessage = `Location mapping suspended at : ${new Date(userAlertData?.endedAt).toLocaleString([], { hour12: true })}`;
          else if (userAlertData?.endedAt && !userAlertData?.isStoppedBySystem)
            alertLastDateMessage = `Alert stopped at : ${new Date(userAlertData?.endedAt).toLocaleString([], { hour12: true })}`;


          setIsEventStop(true);
          setMsgError(
            <div>
              <div
                style={{
                  background: "#00bfff",
                  textAlign: "center",
                  color: "black",
                  fontSize: "15px",
                  borderRadius: "5px",
                  padding: '0.5rem'
                }}
                role="alert"
              >
                {
                  errorMessage.toString()
                }
              </div>

              <p style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0.5rem" }}>
                <span style={{ textAlign: 'left' }}>{alertStartDateMessage.toString()}</span>
                <span style={{ textAlign: 'end' }}>{alertLastDateMessage.toString()} </span>
              </p>
            </div>
          );
        }
        if (
          Date.parse(response?.data?.data?.alarmLocationDetails[0].logTime) >
          Date.parse(response?.data?.data?.userAlertData?.endedAt)
        ) {
          setIsEventStop(true);
          const errorMessage = userAlertData?.isStoppedBySystem ? `${userAlertData?.userAlert?.fullName}'s location will no longer be updated since it has been more than 24 hours since the alert was started. Please reach out to ${response?.data?.data?.userAlertData?.userAlert?.fullName} to check if everything is ok.` : `System has stopped automatic tracking. However, you can use already collected information to track Jesse Livermore.`;
          setMsgError(
            <div
              className="alert"
              style={{
                background: "#D1ECF1",
                textAlign: "center",
                color: "#676767",
                fontSize: "20px",
                borderRadius: "5px",
              }}
              role="alert"
            >
              {
                errorMessage.toString()
              }
            </div>
          );
        }
        if (response?.data?.data?.userAlertData?.endedAt === "") {

          const alertStartDateMessage = `Alert started at : ${new Date(alarmLocationDetails[0]?.logTime).toLocaleString([], { hour12: true })}`;
          let alertLastDateMessage = `Last updated at: ${new Date(alarmLocationDetails[alarmLocationDetails?.length - 1]?.logTime).toLocaleString([], { hour12: true })}`;
          if (userAlertData?.endedAt && userAlertData?.isStoppedBySystem)
            alertLastDateMessage = `Location mapping suspended at : ${new Date(userAlertData?.endedAt).toLocaleString([], { hour12: true })}`;
          else if (userAlertData?.endedAt && !userAlertData?.isStoppedBySystem)
            alertLastDateMessage = `Alert stopped at : ${new Date(userAlertData?.endedAt).toLocaleString([], { hour12: true })}`;

          setMsgError(
            <div>
              <div
                className="alert"
                style={{
                  background: "red",
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "5px",
                }}
                role="alert"
              >
                {/* {nameTitle.charAt(0).toUpperCase()+ nameTitle.slice(1)} */}
                {(response?.data?.data?.userAlertData?.userAlert.fullName).charAt(0).toUpperCase() +
                  response?.data?.data?.userAlertData?.userAlert.fullName.slice(1)}{" "}
                has started the alert and their location will be updated periodically for upto 24 hours.
              </div>
              <p style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0.5rem" }}>
                <span style={{ textAlign: 'left' }}>{alertStartDateMessage.toString()}</span>
                <span style={{ textAlign: 'end' }}>{alertLastDateMessage.toString()} </span>
              </p>
            </div>
          );
        }
      }
      setTimeout(() => setApiCall(true), 30000);
    } catch (error) {
      console.log("here error", error.response.data.statusCode);
      // if (error.response.data.statusCode === 500) {
      setIsEventStop(true);
      setPageerror(false);
      setTimeout(() => {
        navigate(`*`);
      }, 5000);
      // }
    }
  }

  return (
    <>
      {pageError ? (
        <>
          <div className="header-sec">
            <div className="d-flex">
              <img src="/LogoNew.png" height="50px" width="50px" style={{ marginRight: "12px" }} alt="#" />
              <h3 style={{ marginBlock: "auto" }}>
                ResQ - Update on {nameTitle.charAt(0).toUpperCase() + nameTitle.slice(1)}'s location mapping
              </h3>
            </div>
          </div>

          <div className="container pt-3">
            <div className="row p-0">
              <div className="col-12">{msgError}</div>
            </div>
          </div>

          <div className="map_section">
            <MapBle passId={map} isEventStop={isEventStop} />
          </div>
          {/* <br /> */}
          <div className="data-table">
            <div className="row">
              <div className="col-12 p-4">
                {/* {msgError}
                <br /> */}
                <div className="table_section">
                  <div className="header-bottom">Available audio recording</div>
                  <Table passId={map} />
                </div>
                <br />
                <div className="refresh-section" style={{ marginBottom: "30px" }}>
                  <div class="row">
                    <div class="col-8">
                      <p>Disclaimer: Data on this page automatically refreshes every 30 seconds.</p>
                    </div>
                    <div class="col-4">
                      <button className="btn" onClick={() => setApiCall(true)}>
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SpinnerLoad />
      )}
    </>
  );
}